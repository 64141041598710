import React, { useContext, useEffect, useRef, useState } from "react";
import { Disc, Globe, Grid, Link, Package, Radio, Users, Wifi } from "react-feather";
import api from "../../api";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import ScrollButton from "./ScrollButton";
import useAuth from "../../hooks/useAuth";
import DeviceDetail from "../../components/deviceInfo/DeviceDetail";
import NotyfContext from "../../contexts/NotyfContext";
import { imageSet } from "../icons/AssetIcon/IconList";
import { getUrl } from "../../utils/staticMethods";
import defaultImg from "../../assets/img/icon/GPS/placeholder.png";

const icons = {
  assets: <Package size={14} />,
  people: <Users size={14} />,
  zones: <Disc size={14} />,
  trackers: <Globe size={14} />,
  readers: <Wifi size={14} />,
  tags: <Radio size={14} />,
  "archived-assets": <Package size={14} />,
}

const GlobalSearch = () => {
  const [data, setData] = useState([]);
  const [name, setName] = useState(null);
  const [wirepas, setWirepas] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation()
  const { searchString } = queryString.parse(location.search);
  const { user } = useAuth()
  const [showEditModal, setShowEdit] = useState(null);
  const [type, setType] = useState(null)
  const notify = useContext(NotyfContext);
  const refObj = {
    assets: useRef(),
    people: useRef(),
    zones: useRef(),
    trackers: useRef(),
    readers: useRef(),
    tags: useRef(),
    "archived-assets": useRef(),
  }

  const columns = {
    assets: [
      {
        Header: "",
        accessor: "icon",
        type: "icon"
      },
      {
        Header: "Name (Ref)",
        accessor: "compoundName",
        type: "link",
        link: "/assets/edit",
        stateId: "id"
      },
      {
        Header: "Fleet Number",
        accessor: "fleetNumber",
      },
      {
        Header: "Last Seen",
        accessor: "lastSeen",
        type: "dateFromNow"
      },
      {
        Header: "Hashtags",
        accessor: "hashTags",
        disableSortBy: true,
        Cell: cell => (<div>
          {cell.value && cell.value.map((tag, i) => <NavLink key={i} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
        </div>)
      }
    ],
    people: [
      {
        Header: "",
        accessor: "icon",
        type: "icon"
      },
      {
        Header: "Name (Ref)",
        accessor: "compoundName",
        type: "link",
        link: "/people/edit",
        stateId: "id"
      },
      {
        Header: "Last Seen",
        accessor: "lastSeen",
        type: "dateFromNow"
      },
      {
        Header: "Hashtags",
        accessor: "hashTags",
        disableSortBy: true,
        Cell: cell => (<div>
          {cell.value && cell.value.map((tag, i) => <NavLink key={i} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
        </div>)
      }
    ],
    zones: [
      {
        Header: "",
        accessor: "icon",
        Cell: cell => (<img className="table-icon" alt="table-icon"
          src={imageSet[getUrl(cell.value)] ? require("../../assets/img/icon/" + imageSet[getUrl(cell.value)]).default : defaultImg} />)
      },
      {
        Header: "Name (Ref)",
        accessor: "compoundName",
        type: "link",
        stateId: "id",
        link: "/zones/edit"
      },
      {
        Header: "Contents",
        accessor: "contentsCount",
      },
      {
        Header: "Hashtags",
        accessor: "hashTags",
        disableSortBy: true,
        Cell: cell => (<div>
          {cell.value && cell.value.map((tag, i) => <NavLink key={i} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
        </div>)
      }
    ],
    trackers: [
      {
        Header: "#Serial",
        accessor: "serial",
        Cell: (cell) => {
          if (user?.role === 'Root') {
            return <NavLink to={{ pathname: "/trackers/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>;
          } else if (user?.role === 'Admin') {
            return <span onClick={() => { setType('trackers'); setShowEdit(cell.row.original) }} className="text-primary cursor-pointer">{cell.value}</span>
          } else {
            return <span>{cell.value}</span>
          }
        }
      },
      {
        Header: "Last Seen",
        accessor: "lastReport",
        type: "dateFromNow"
      },
      {
        Header: "Linked",
        accessor: "customerId",
        type: "linkStatus",
      }
    ],
    readers: [
      {
        Header: "#Serial",
        accessor: "serial",
        Cell: (cell) => {
          if (user?.role === 'Root') {
            return <span>
              <NavLink to={{ pathname: "/readers/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>
              {cell.row.original.wirepasNetworkId && <Grid className="ms-2" size={16} />}
              {cell.row.original.wirepas && <Link className="ms-1" size={16} />}
            </span>;
          } else if (user?.role === 'Admin') {
            return <span onClick={() => { setType('readers'); setName(cell.row.original.deviceName); setWirepas(cell.row.original.wirepas); setShowEdit(cell.row.original) }} className="text-primary cursor-pointer">{cell.value}</span>
          } else {
            return <span>{cell.value}</span>
          }
        }
      },
      {
        Header: "Name",
        accessor: "deviceName",
      },
      {
        Header: "Last Seen",
        accessor: "lastReport",
        type: "dateFromNow"
      },
      {
        Header: "Linked",
        accessor: "customerId",
        type: "linkStatus",
      }
    ],
    tags: [
      {
        Header: "#Serial",
        accessor: "serial",
        Cell: (cell) => {
          if (user?.role === 'Root') {
            return <NavLink to={{ pathname: "/tags/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>;
          } else if (user?.role === 'Admin') {
            return <span onClick={() => { setType('tags'); setShowEdit(cell.row.original) }} className="text-primary cursor-pointer">{cell.value}</span>
          } else {
            return <span>{cell.value}</span>
          }
        }
      },
      {
        Header: "Last Seen",
        accessor: "lastReport",
        type: "dateFromNow"
      },
      {
        Header: "Linked",
        accessor: "customerId",
        type: "linkStatus",
      }
    ],
    "archived-assets": [
      {
        Header: "",
        accessor: "icon",
        type: "icon"
      },
      {
        Header: "Name (Ref)",
        accessor: "compoundName",
        type: "link",
        link: "/archived-assets/edit",
        stateId: "id"
      },
      {
        Header: "Fleet Number",
        accessor: "fleetNumber",
      },
      {
        Header: "Last Seen",
        accessor: "lastSeen",
        type: "dateFromNow"
      },
      {
        Header: "Hashtags",
        accessor: "hashTags",
        disableSortBy: true,
        Cell: cell => (<div>
          {cell.value && cell.value.map((tag, i) => <NavLink key={i} to={{ pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}` }}><span className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
        </div>)
      }
    ],
  }

  useEffect(() => {
    if (searchString) {
      onSearch();
    }
  }, [searchString])
  const onSearch = () => {
    let result
    const apiNameArr = ['assets', 'people', 'zones', 'trackers', 'readers', 'tags', 'archived-assets']
    if (searchString.indexOf('#') === 0) {
      apiNameArr.splice(3, 3)
    }
    const apiArray = [];
    apiNameArr.forEach(item => {
      apiArray.push(api.get(`${item}?page=1&limit=999`, {
        params: {
          filter: searchString
        }
      }))
    })
    setLoading(true);
    Promise.all(apiArray).then(res => {
      res.map((item, index) => item.type = apiNameArr[index]);
      result = res.filter(item => item.data.length > 0);
      setData(result);
      setLoading(false);
    }).catch(() => setLoading(false))
  }
  
  const getFirstLetterUpperCase = (str) => {
    // For achived assets, look for dash and remove
    if (str.includes('-')) {
      const parts = str.split('-');
      return getFirstLetterUpperCase(parts[0]) + " " + getFirstLetterUpperCase(parts[1]);
    }
    else{
      return str.slice(0, 1).toUpperCase() + str.slice(1);
    }
  }

  const scrollInto = (ele) => {
    let element = document.getElementById(ele);
    element && element.scrollIntoView({ behavior: 'smooth' })
  }

  const onSave = () => {
    let obj = JSON.parse(JSON.stringify(showEditModal))
    obj.deviceName = name;
    obj.wirepas = wirepas;
    api.put(`readers`, obj)
      .then(res => {
        notify.open({
          type: 'success',
          message: 'Changes Saved'
        })
        api.get(`readers?page=1&limit=999&filter=${searchString}`).then(res => {
          setData(prevState => {
            let arr = prevState.concat([])
            let filter = arr.filter(item => item.type === 'readers')[0]
            filter.data = res.data
            return arr
          })
        })
        setShowEdit(null)
      });
  }

  return (<React.Fragment>
    {loading ? <Spinner animation="border" /> : <div>
      <div className="mb-4">
        {data.length > 0 && data.map((item, index) => <Button onClick={() => scrollInto(item.type)} key={index} className="me-3 mb-2 fw-bold">
          {icons[item.type]} {getFirstLetterUpperCase(item.type)} ({item.data.length})
        </Button>)}
      </div>
      <div>
        {data.length > 0 && data.map(item => <div key={item.type}>
          <h3 className="mb-3" id={item.type}>{getFirstLetterUpperCase(item.type)} ({item.data.length})</h3>
          <Card className="mb-4" key={item.type}>
            <Card.Body className="d-flex flex-column ">
              <CommonTable ref={refObj[item.type]} hideSearch data={item.data} columns={columns[item.type]} />
            </Card.Body>
          </Card>
        </div>)}
      </div>
      {data.length > 0 && <ScrollButton />}
      {data.length === 0 && <h2>No data found</h2>}
    </div>}
    <Modal show={showEditModal} onHide={() => setShowEdit(null)}>
      <Modal.Header closeButton>
        <Modal.Title>{showEditModal?.serial}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DeviceDetail onChangeName={(e) => setName(e)} onChangeWirepas={(e) => setWirepas(e)} data={showEditModal} type={type} />
      </Modal.Body>
      <Modal.Footer>
        {user?.role === 'Admin' && type === 'readers' && <Button onClick={() => onSave()}>Save</Button>}
        <Button onClick={() => setShowEdit(false)} variant={'secondary'}>Close</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>)
}
export default GlobalSearch;